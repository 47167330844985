<div id="main-left-col">
    <!-- <div class=" text-center" > -->

    <div class="p-2">
        <div class="col-lg-12 col-md-12 ">
            <img src="assets/images/me_sea.jpg" id="rounded_me_image" />
        </div>
    </div>
    <div class="p-1 mt-3">
        <p class="font-weight-bold">Giovanni Pasquariello</p>
    </div>
    <div class="p-1 occupation">
        <span>Web Developer in Italy</span>
    </div>
    <div class="p-1">
        <ul class="nav flex-column nav-menu">
            <li class="nav-item">
                <a class="nav-link active" href="#app-page">HOME</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#about-page">ABOUT</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#skill-page">SKILLS</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#education-page">EDUCATION</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#experience-page">EXPERIENCE</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#personal-work-page">WORK</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#contact-page">CONTACT</a>
            </li>
        </ul>
    </div>
</div>