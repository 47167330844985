<link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous" />

<title>GET IN TOUCH</title>

<h2>CONTACT</h2>

<div class="row">
    <span>Below you can find all links to keep in touch with me. You can drop me a message on Telegram to have a quick chat. Emails are an evergreen if you want to keep it professional!</span>
    <p>You can also download my Curriculum vitae below!</p>
</div>





<div class=" row icons text-center">
    <div class="col-1"></div>

    <div class="col-sx-3 col-md-2 col-lg-1">
        <a target="_blank" href="https://t.me/mr_gionsi"><i class="fab fa-2x fa-telegram-plane fa-light m-5 grow dark"></i></a>
    </div>
    <div class="col-sx-2 col-md-2 col-lg-1">
        <a target="_blank" href="mailto:g.pasquariello96@gmail.com"><i class="fas fa-2x fa-envelope-open-text fa-light m-5 grow dark"></i></a>
    </div>
    <div class="col-sx-2 col-md-2 col-lg-1">
        <a target="_blank" href="tel:3454526037"><i class="fas fa-2x fa-envelope-open-text fa-phone m-5 grow dark"></i></a>
    </div>

    <div class="col-2">
    </div>
    <div class="col-sx-2 col-md-2 col-lg-1">
        <a target="_blank" href="../../assets/downloads/CV-Pasquariello Giovanni.pdf"><i class="fas fa-2x fa-file-download fa-light m-5 grow dark"></i></a>
    </div>
    <div class="col-sx-2 col-md-2 col-lg-1">
        <a target="_blank" href="http://github.com/mrgionsi"><i class="fas fa-2x fa-code-branch fa-light m-5 grow dark"></i></a>
    </div>

    <div class="col-sx-2 col-md-2 col-lg-1">
        <a target="_blank" href="https://www.facebook.com/giovanni.pasquariello.16"><i class="fab fa-2x fa-facebook-f fa-light m-5 grow dark"></i></a>
    </div>
    <div class="col-sx-2 col-md-2 col-lg-1">
        <a target="_blank" href="https://instagram.com/mr_gionsi/"><i style="border-radius:40%;" class="fab fa-2x fa-instagram fa-light m-5 grow dark"></i></a>
    </div>
    <div class="col-sx-2 col-md-2 col-lg-1">
        <a target="_blank" href="https://www.linkedin.com/in/giovanni-pasquariello-5a6215151/"><i class="fab fa-2x fa-linkedin-in fa-light m-5 grow dark"></i></a>
    </div>

</div>

<div class="row">
    <small>Made with Angular &#10084;&#65039;. Copyright Ⓒ Giovanni Pasquariello 2021</small>
</div>