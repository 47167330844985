<title>ABOUT US</title>

<h2>WHO AM I?</h2>
<p>
    Hi I'm Giovanni Pasquariello and I am 25 yo. I come from
    <a target="_blank" href="https://it.wikipedia.org/wiki/Recale">Recale </a>(CE). I am currently studing computer science at University of Salerno and in the meanwhile I am working as Integration Engineer at Ericsson Naples. "Work makes man nobles",
    my dad always said to me. I worked as waiter for many years when I was 17, in order to be indipendent. After first univeristy year I started to work on IT. I love play football and listen music. My hobbies are dancing, football with friends and coding
    of course. I learned during my work experience to help the other people to solve their problems, in every scope. This is important for a great team-work!
</p>
<p>My motto is always:</p>

<div class="row" style="text-align: center;">
    <title>BE LIQUID MY FRIEND.</title>
</div>

<p>Be flexible like the water, as it can adapt itself to every container, the same you should do in every situation: adapt yourself to the situation to solve your problem.</p>
<p>This concept accompanies me along the working life as well :)</p>