<div class="timeline">
    <div class="entry">
        <div class="title" data-aos="fade-right">
            <h3>2019 - Present</h3>
            <p>IE, Ericsson</p>
        </div>
        <div class="body" data-aos="fade-left">
            <p>Integration Engineer, Ericsson</p>
            <p>Engineer for dynamic activation system, provisioning and sim activation for O2 UK Customer. Experience on service provisioning ( 2G, 3G, 4G and VoLTE ). QoS for customer solution, assurance of working system. System integration, based on the
                requirement. Use of Agile Methodology in a non-development project. Design, test verification and test management are reported on Jira/Confluence. Use of XRay plugin on Jira for manual testing. Owner of testing automation software. (See
                Work section).</p>
            <p><small><a href="www.ericsson.com"> Ericsson</a></small></p>
        </div>
    </div>
    <div class="entry">
        <div class="title" data-aos="fade-right">
            <h3>2017 - 2018</h3>
            <p>Web Developer, 3EM Group</p>

        </div>
        <div class="body" data-aos="fade-left">
            <p>Developer, 3EM Group</p>
            <p>Internship for web development - HTML, CSS, Javascript.</p>
            <p>Development of Monitoring system using .NET Framework and pure web technologies ( HTML, CSS, JS). </p>

        </div>
    </div>
</div>