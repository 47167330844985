<title>MY WORK</title>

<h2>RECENT WORK</h2>


<div class="row">
    <div class="col-sx-12 col-md-5 col-lg-5">
        <div class="services service-2" data-aos="fade-down-left">

            <div class="desc">
                <h4>U-Work </h4>
                <p>
                    U-Work Co-Producer 
                </p>
                <ul>
                    <li>
                        Python Flask
                    </li>
                    <li>Angular 16</li>
                    <li>ORM,Postgres</li>
                    <li>Docker</li>
                    <li>Jitsi Meet Self Hostes and customized</li>
                    <li>Keycloak Integration for Authentication</li>

                </ul>
                <p><small><a target="_blank" href="https://u-work.it">Go to website</a></small></p>

            </div>
        </div>
    </div>
    <div class="col-sx-12 col-md-5 col-lg-5">
        <div class="services service-2" data-aos="fade-down-left">

            <div class="desc">
                <h4>IT Gestionale Magazzino </h4>
                <p>
                    Gestionale Magazzino developed to handle product storing and selling.
                    Made with Angular, Angular Material, Python, Postgres.
                </p>
                <ul>
                    <li>
                        Python Flask, Guvicorn
                    </li>
                    <li>Angular 15</li>
                    <li>Angular Material</li>
                    <li>ORM,Postgres</li>
                    <li>Docker</li>
                </ul>
                <p><small><a target="_blank" href="https://gestionale-magazzino.gionsihome.duckdns.org/">Go to website</a></small></p>
                <small>Access with Username = admin; Password = Admin123.</small>
            </div>
        </div>
    </div>
    <div class="col-sx-12 col-md-5 col-lg-5">
        <div class="services service-1" data-aos="fade-down-right">

            <div class="desc">
                <h4>ClickEat</h4>
                <p>University project. Development of restaurant software management. Requirement was to create a
                    software to handle customer orders, generate the bill and take the ordination.
                </p>
                <p>Use of Java, Java Servlet and HTML, CSS, JS. MySQL has been used as database.</p>
                <span>For demo: </span>
                <ul>
                    <li>Username: g.pasquariello</li>
                    <li>Password: g.pasquariello</li>
                </ul>
                <p><small><a target="_blank" href="http://clickeat.herokuapp.com/">Go to website</a></small></p>
                <small>Note: Website is hosted on free website, connection could be slow.</small>
            </div>
        </div>
    </div>
    <div class="col-sx-12 col-md-5 col-lg-5">
        <div class="services service-2" data-aos="fade-down-left">

            <div class="desc">
                <h4>Testing Automation </h4>
                <p>
                    Owner of testing automation software for E///. Idea was born performing tests in a manual way, to
                    speed up the testing process and testing verification. Use of Agile Methodology, from requirement on
                    Confluence to task on Jira. Adopting DevOps philosophy
                    , creating a pipeline for CI/CD from code analysis, compile, testing and deploy (On "server" and/or
                    Docker creation). Jira Integration, using Jira API to report an issue on a space and attaching
                    evidence. Technologies used: </p>
                <ul>
                    <li>
                        Java Spring Boot
                    </li>
                    <li>Angular 8</li>
                    <li>JPA,H2 In memory</li>
                    <li>Docker</li>
                </ul>
                <small> Future idea will be to migrate monothic application to Microservices architecture.</small>

            </div>
        </div>
    </div>
</div>