<title>SKILLS</title>

<h2>MY SKILLS</h2>

<div class="row">
    <div class="col-lg-6 col-md-6 col-sx-12">

        <div class="progress-wrap">
            <h3>HTML</h3>

            <div class="progress">

                <div data-aos="fade-right" class="progress-bar color-3" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style="width:80%">
                    <span>80%</span>
                </div>
            </div>
        </div>
        <div class="progress-wrap">
            <h3>CSS</h3>
            <div class="progress">
                <div data-aos="fade-right" class="progress-bar color-4" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width:75%">
                    <span>75%</span>
                </div>
            </div>
        </div>
        <div class="progress-wrap">
            <h3>Javascript</h3>
            <div class="progress">
                <div data-aos="fade-right" class="progress-bar color-5" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width:75%">
                    <span>75%</span>
                </div>
            </div>
        </div>
        <div class="progress-wrap">
            <h3>Angular</h3>
            <div class="progress">
                <div data-aos="fade-right" class="progress-bar color-2" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width:70%">
                    <span>70%</span>
                </div>
            </div>
        </div>
        <div class="progress-wrap">
            <h3>Python</h3>
            <div class="progress">
                <div data-aos="fade-right" class="progress-bar color-9" role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100" style="width:45%">
                    <span>45%</span>
                </div>
            </div>
        </div>

    </div>
    <div class="col-lg-6 col-md-6 col-sx-12">
        <div class="progress-wrap">
            <h3>Java</h3>
            <div class="progress">
                <div data-aos="fade-right" class="progress-bar color-10" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100" style="width:85%">
                    <span>85%</span>
                </div>
            </div>
        </div>

        <div class="progress-wrap">
            <h3>Docker</h3>
            <div class="progress">
                <div data-aos="fade-right" class="progress-bar color-6" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width:70%">
                    <span>70%</span>
                </div>
            </div>
        </div>
        <div class="progress-wrap">
            <h3>Kubernetes</h3>
            <div class="progress">
                <div data-aos="fade-right" class="progress-bar color-7" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width:50%">
                    <span>50%</span>
                </div>
            </div>
        </div>
        <div class="progress-wrap">
            <h3>DevOps</h3>
            <div class="progress">
                <div data-aos="fade-right" class="progress-bar color-8" role="progressbar" aria-valuenow="73" aria-valuemin="0" aria-valuemax="100" style="width:73%">
                    <span>73%</span>
                </div>
            </div>
        </div>
    </div>
</div>