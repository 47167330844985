<div class="container-fluid p-0 ">
    <div class="row">
        <div class="col-lg-3 col-md-3 col-sx-0  text-center  pl-0 row-left" id="left-menu-div">
            <app-left-menu></app-left-menu>
        </div>
        <div class="col-lg-3 col-md-3 col-sx-0  text-center pl-0 row-left">
        </div>
        <div class="col-sx-12  col-lg-9 col-md-9  row-left" id="app-page">
            <div class="row row-left">
                <div class="col-12">
                    <div id="welcome_component">
                        <div id="hello-p">
                            <p data-aos="fade-up-left" data-aos-delay="300">
                                Ciao!
                            </p>
                            <p data-aos="fade-up-left" data-aos-delay="300">I'm Giovanni</p>
                            <small data-aos-delay="300" data-aos="fade-up-right">Studying computer Science at <a target="_blank" href="https://www.unisa.it/">Unisa</a>.</small><br>
                            <small data-aos-delay="300" data-aos="fade-up-right"> Integration Engineer at <a target="_blank"  href="https://www.ericsson.com/en">Ericsson</a> since 2019.</small><br>
                            <small data-aos-delay="300" data-aos="fade-up-right">Actually learning microservices architecture.</small><br>
                            <a data-aos-delay="300" data-aos="fade-up-right" href="#about-page"><button type="button" class="btn btn-light mt-3">View portfolio</button></a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row center" id="about-page">
        <div class="col-lg-3 col-md-3 col-sx-0 text-center ">
        </div>
        <div class="col-sx-12 col-lg-9 col-md-9  mt-5 ">
            <app-about-page></app-about-page>
        </div>
    </div>
    <div class="row center" id="skill-page">
        <div class="col-lg-3 col-md-3 col-sx-0 text-center ">
        </div>
        <div class="col-sx-12 col-lg-9 col-md-9  mt-5 ">
            <app-skill-page></app-skill-page>
        </div>
    </div>
    <div class="row center" id="education-page">
        <div class="col-lg-3 col-md-3 col-sx-0 text-center ">
        </div>
        <div class="col-sx-12 col-lg-9 col-md-9 mt-5 ">
            <app-education-page></app-education-page>
        </div>
    </div>
    <div class="row center" id="experience-page">
        <div class="col-lg-3 col-md-3 col-sx-0 text-center ">
        </div>
        <div class="col-sx-12 col-lg-9 col-md-9  mt-5 ">
            <app-experience-page></app-experience-page>
        </div>
    </div>
    <div class="row center" id="personal-work-page">
        <div class="col-lg-3 col-md-3 col-sx-0 text-center ">
        </div>
        <div class="col-sx-12 col-lg-9 col-md-9 mt-5 ">
            <app-personal-work-page></app-personal-work-page>
        </div>
    </div>
    <div class="row center" id="contact-page">
        <div class="col-lg-3 col-md-3 col-sx-0 text-center ">
        </div>
        <div class="col-sx-12 col-lg-9 col-md-9 mt-5 ">
            <app-contact-page></app-contact-page>
        </div>
    </div>

</div>